<template>
  <div class="equipment-fault-add">
    <van-nav-bar
      title="报故障"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />
    <div class="equipment-fault-box">
      <van-form ref="form" class="fault-box-form">
        <van-field
          v-model="form.equipmentNo"
          label="设备位号"
          readonly
          placeholder="请选择"
          input-align="right"
        />
        <van-field
          v-model="form.equipmentName"
          label="设备名称"
          readonly
          placeholder="请选择"
          input-align="right"
        />
        <van-field
          v-model="form.typeName"
          is-link
          label="故障类型"
          readonly
          placeholder="请选择"
          input-align="right"
          :rules="[{ required: true, message: '请选择故障类型' }]"
          @click="visiable = true"
        />
        <van-field
          v-model="form.description"
          label="故障描述"
          placeholder="请输入"
          type="textarea"
          rows="2"
          autosize
          maxlength="500"
          show-word-limit
          input-align="right"
          :rules="[{ required: true, message: '请输入故障描述' }]"
        />
      </van-form>
      <SelectPopupGrade
        ref="selectPopupGrade"
        v-model="form.typeName"
        :multi="false"
        :immediately="true"
        title="故障类型"
        :list="faultTypes"
        list-key="value"
        :visible="visiable"
        @change="popupGradeChange"
        @close="visiable = false"
      />
      <DevicePopup
        popup-height="70%"
        :visible="deviceVisible"
        @close="deviceVisible = false"
        @change="onDeviceChange"
      />
    </div>
    <div class="fault-submit">
      <van-button block type="primary" round @click="commitDeviceFault"
        >提 交</van-button
      >
    </div>
  </div>
</template>

<script>
import { commitDeviceFault, getFaultType } from "@/api/basics/equipment";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import DevicePopup from "@/components/DevicePopup.vue";
import { operateDialog } from "@/utils";
export default {
  components: {
    SelectPopupGrade,
    DevicePopup
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visiable: false,
      deviceVisible: false, // 设备popup
      form: {
        type: "",
        typeName: "",
        equipmentId: "",
        equipmentName: "",
        description: ""
      },
      faultTypes: []
    };
  },
  created() {
    // console.log("this.qqq", this.$route.params);
    const { info } = this.$route.params;
    this.form.equipmentName = info.name;
    this.form.equipmentNo = info.machineNo;
    this.getSelects();
  },
  methods: {
    onDeviceChange(v) {
      this.form.equipmentId = v.id;
      this.form.equipmentName = v.name;
    },
    popupGradeChange(v) {
      console.log("v", v);
      const [item] = v;
      this.form.type = item.value;
      this.form.typeName = item.label;
    },
    getSelects() {
      // 获取故障类型
      getFaultType().then(res => {
        this.faultTypes = res;
      });
    },
    commitDeviceFault() {
      this.$refs.form.validate().then(async () => {
        try {
          await operateDialog({ message: "确定提交此故障?" });
          const params = {
            type: this.form.type, // 故障类型
            description: this.form.description,
            equipmentRecordId: [this.id]
          };
          commitDeviceFault(params)
            .then(res => {
              if (res) {
                this.$toast.success("提交成功");
                this.$router.back();
              }
            })
            .catch(e => {
              this.$toast.fail("提交失败, 请重试");
            });
        } catch (e) {
          console.log("e", e);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-fault-add {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-fault-box {
    flex: 1;
    height: 0;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .fault-submit {
    padding: 8px;
  }
}
</style>
